/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { TextGradient } from 'src/components/text-gradient';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
// import { paths } from 'src/routes/paths';
import { useSelector } from 'src/redux/store';
// components
import Logo from 'src/components/logo';
import { Skeleton } from '@mui/material';
//
import { useGetPageQuery } from 'src/redux/services/salesrush/endpoints';
//
import usePageUser from 'src/store/page-user';
import { HEADER } from '../config-layout';
import { homeMenu, moreMenu, navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { SettingsButton, HeaderShadow } from '../_common';
import PhoneButton from '../_common/phone-button';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function Header({ fullwidth = false, nav = true }) {
  const theme = useTheme();

  const { user } = usePageUser()

  // const { user } = useSelector(state => state.page);
  const [subdomain] = useState(window.location.host.split('.'));
  const { data, isLoading } = useGetPageQuery(subdomain[0]);
  const [logo_name, setLogoName] = useState(null)


  console.log('user => ', Object.keys(user).length)
  // const logo_name =
  //   Object.keys(user).length > 0 && user?.user_level === 'salesperson'
  //     ? Object.keys(user).length > 0 ? `${user?.firstname} ${user?.lastname}` : <Skeleton variant="rectangular" width={150} height={40} />
  //     : Object.keys(user).length > 0 ? user?.company_name : <Skeleton variant="rectangular" width={150} height={40} />;
  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const isObject = (value) => {
    return typeof value === 'object'
      && value !== null
      && !Array.isArray(value)
      && !(value instanceof RegExp)
      && !(value instanceof Date)
      && !(value instanceof Set)
      && !(value instanceof Map)
  }

  useEffect(() => {
    if (user) {
      setLogoName(user?.user_level === 'salesperson' ? `${user?.firstname} ${user?.lastname}` : user?.company_name)
    }
  }, [user])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar>
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            '.css-97ust4': {
              height: '0px !importan',
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(offsetTop && {
              ...bgBlur({
                color: theme.palette.background.default,
              }),
              height: {
                // md: HEADER.H_DESKTOP_OFFSET,
              },
            }),
          }}
        >
          <Container sx={{
            height: !mdUp ? {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            } : {
              xs: HEADER.H_DESKTOP,
              md: HEADER.H_MOBILE,
            },
            display: 'flex',

          }} maxWidth={fullwidth ? false : '100%'}>
            {isLoading ? (
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                style={{ marginRight: 5, margin: 'auto' }}
              />
            ) : (
              <Logo />
            )}
            {
              logo_name ? <TextGradient

                animate={{ backgroundPosition: '200% center' }}
                style={{
                  margin: 'auto',
                  fontSize: mdUp ? 24 : 18,
                  maxWidth: !mdUp ? '180px' : 'auto',
                  textOverflow: !mdUp ? 'ellipsis' : 'auto',
                  overflow: !mdUp ? 'hidden' : 'auto',
                  whiteSpace: !mdUp ? 'nowrap' : 'auto'
                }}
                transition={{
                  repeatType: 'reverse',
                  ease: 'linear',
                  duration: 20,
                  repeat: Infinity,
                }}
                title={logo_name}
              /> : <Skeleton variant="rectangular" width={150} height={40} />
            }
            <Box sx={{ flexGrow: 1 }} />
            {mdUp && nav && <NavDesktop offsetTop={offsetTop} home={homeMenu} more={moreMenu} />}
            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>

              <PhoneButton />

              <SettingsButton
                sx={{
                  ml: { xs: 1, md: 0 },
                  mr: { md: 2 },
                }}
              />

              {!mdUp && nav && <NavMobile offsetTop={offsetTop} home={homeMenu} more={moreMenu} />}
            </Stack>
          </Container>
        </Toolbar>

        {offsetTop && <HeaderShadow />}
      </AppBar>
    </Box>
  );
}

Header.propTypes = {
  fullwidth: PropTypes.bool,
  nav: PropTypes.bool,
};
