import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useGetPageQuery } from 'src/redux/services/salesrush/endpoints';
import { formatPhoneNumber } from 'src/utils/phone-format';
import { useState } from 'react';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';

// ----------------------------------------------------------------------

export default function PhoneButton({ sx }) {

  const [subdomain] = useState(window.location.host.split("."));

  const { data, isLoading } = useGetPageQuery(subdomain[0]);

  return (

    // <Button
    //   sx={{
    //     width: 20,
    //     color: grey[500]
    //   }}

    //   size="small"
    //   variant="text"
    //   href={`tel:${formatPhoneNumber(data?.user?.cell_phone_number)}`}
    // >
    //   <Iconify icon="solar:phone-bold" width={24} />
    // </Button>
    <IconButton

      whileTap="tap"
      whileHover="hover"
      variants={varHover(1.05)}
      aria-label="settings"
      href={`tel:${formatPhoneNumber(data?.user?.cell_phone_number)}`}
      sx={{
        width: 40,
        height: 40,
      }}
    >
      <Iconify icon="solar:phone-bold" width={24} />
    </IconButton>

  );
}

PhoneButton.propTypes = {
  sx: PropTypes.object,
};
