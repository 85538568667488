/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
//
import { useEffect, useState } from 'react';
import { useGetPageQuery } from 'src/redux/services/salesrush/endpoints';
import Iconify from 'src/components/iconify';
import usePageUser from 'src/store/page-user';
import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavDesktop({ offsetTop, home, more }) {

  const [menu, setMenu] = useState([])
  const { user, setPageClean } = usePageUser()

  useEffect(() => {



    let newMenu = [].concat(home)
    const landings = user?.landings

    for (let i in landings) {
      const key = landings[i]
      newMenu.push({
        title: key.page_name,
        icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: `/landing/${key.url_path}`,
      })
    }

    newMenu = newMenu.concat(more)
    setMenu(newMenu)
  }, [user])

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
      {menu.map((link) => (
        <NavList key={link.title} item={link} offsetTop={offsetTop} />
      ))}
    </Stack>
  );
}

NavDesktop.propTypes = {
  home: PropTypes.array,
  more: PropTypes.array,
  offsetTop: PropTypes.bool,
};
