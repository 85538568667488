import styled from "@emotion/styled";
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { textGradient } from "src/theme/css";
import { Link } from "@mui/material";
import { RouterLink } from "src/routes/components";



const StyledTextGradient = styled(m.h1)(({ theme }) => ({
  ...textGradient(
    `300deg, ${theme.palette.primary.main} 0%, ${theme.palette.warning.main} 25%, ${theme.palette.primary.main} 50%, ${theme.palette.warning.main} 75%, ${theme.palette.primary.main} 100%`
  ),
  padding: 0,
  textTransform: 'capitalize',
  lineHeight: 2,
  textAlign: 'center',
  backgroundSize: '400%',
  fontSize: `18px`,
  fontFamily: "'Barlow', sans-serif",
  [theme.breakpoints.up('md')]: {
    fontSize: `${20}px`,
  },
}));

const TextGradient = ({ title, style, transition, animate, disabledLink = false }) => {
  if (disabledLink) {
    return (
      <StyledTextGradient
        transition={{ ...transition }}
        animate={{ ...animate }}
        style={{ ...style }}>
        {title || ''}
      </StyledTextGradient>)
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      <StyledTextGradient
        transition={{ ...transition }}
        animate={{ ...animate }}
        style={{ ...style }}>
        {title || ''}
      </StyledTextGradient>
    </Link>
  )
};


TextGradient.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape({
    fontSize: PropTypes.string,
  }),
  transition: PropTypes.object,
  animate: PropTypes.object,
  disabledLink: PropTypes.bool
}


export default TextGradient;